import React, { useState } from "react";
import { apiLeadRegister } from "../../../api/lead";
import './ContactForm.scss';

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [feedback, setFeedback] = useState("");
  const [errors, setErrors] = useState({
    name: false,
    company: false,
    phone: false,
    email: false,
    message: false,
  });

  const validateForm = () => {
    const newErrors = {
      name: !name,
      company: !company,
      phone: !phone,
      email: !email,
      message: !message,
    };

    setErrors(newErrors);

    return !Object.values(newErrors).includes(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const payload = {
      name,
      company,
      email,
      telephone: phone,
      origin: "site",
      fields: { message }
    };

    setLoading(true);
    apiLeadRegister(payload)
      .then(() => {
        setLoading(false);
        setFeedback("OBRIGADO, ENTRAREMOS EM CONTATO.");
        resetForm();
      })
      .catch(() => {
        setLoading(false);
        setFeedback("ERRO AO ENVIAR A MENSAGEM, ENTRE EM CONTATO COM (11)91952-2455.");
        resetForm();
      });
  };

  const resetForm = () => {
    setName("");
    setCompany("");
    setPhone("");
    setEmail("");
    setMessage("");
    setErrors({
      name: false,
      company: false,
      phone: false,
      email: false,
      message: false,
    });
  };

  const handleChange = (e, field) => {
    const { value } = e.target;
    switch (field) {
      case "name":
        setName(value);
        break;
      case "company":
        setCompany(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }
    // Clear error for this field if it starts getting filled
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: !value,
    }));
  };

  return (
    <div className="contact-form">
      <form onSubmit={handleSubmit}>
        <div className="container-group">
          <div className="form-group">
            <input
              type="text"
              placeholder="Nome"
              value={name}
              onChange={(e) => handleChange(e, "name")}
              className={errors.name ? 'error' : ''}
            />
            {errors.name && <p className="error-message">Campo obrigatório</p>}
          </div>
          <div className="form-group">
            <input
              type="text"
              placeholder="Empresa"
              value={company}
              onChange={(e) => handleChange(e, "company")}
              className={errors.company ? 'error' : ''}
            />
            {errors.company && <p className="error-message">Campo obrigatório</p>}
          </div>
        </div>
        <div className="container-group">
          <div className="form-group">
            <input
              type="text"
              placeholder="Telefone"
              value={phone}
              onChange={(e) => handleChange(e, "phone")}
              className={errors.phone ? 'error' : ''}
            />
            {errors.phone && <p className="error-message">Campo obrigatório</p>}
          </div>
          <div className="form-group">
            <input
              type="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => handleChange(e, "email")}
              className={errors.email ? 'error' : ''}
            />
            {errors.email && <p className="error-message">Campo obrigatório</p>}
          </div>
        </div>
        <div className="form-group">
          <textarea
            rows="2"
            placeholder="Gostaria de comentar alguma coisa?"
            value={message}
            onChange={(e) => handleChange(e, "message")}
            className={errors.message ? 'error' : ''}
          ></textarea>
          {errors.message && <p className="error-message">Campo obrigatório</p>}
        </div>
        <button type="submit">ENVIAR</button>
      </form>
      {feedback && <p className="feedback-message">{feedback}</p>}
      {loading && <p className="feedback-message">Enviando...</p>}
    </div>
  );
};

export default ContactForm;
