import React from 'react'
import Banner from '../../components/ui/Banner/Banner'
import FeedCases from '../../components/ui/FeedCases/FeedCases'
import Partners from '../../components/ui/Partners/Partners'
import './Home.scss'

const Home = ({ setInvertedNavbar }) => {
  setInvertedNavbar(true)
  return (
    <div className='Home'>
      <Banner />
      <FeedCases />
      <Partners />
    </div>
  )
}

export default Home
