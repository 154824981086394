import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/images/banner/arrow-white.svg';
import ImageWithBadge from '../../components/ui/ImageWithBadge/ImageWithBadge';
import { casesListing } from '../../utils/casesListing';
import './CaseCompleted.scss';
import { imageWithBadgeData } from './imageWithBadgeData';

const CaseCompleted = () => {
  const [objectData, setObjectData] = useState(null);
  const { id } = useParams();
  const [randomItems, setRandomItems] = React.useState([]);
  const navigate = useNavigate();
  const navigateCase = (id) => {
    window.scrollTo(0, 0)
    navigate(`/cases/completed/${id}`)
  }

  useEffect(() => {
    const items = getRandomItems(imageWithBadgeData, 2);
    setRandomItems(items);
  }, []);

  const getRandomItems = (items, count) => {
    const groupedItems = items.reduce((acc, item) => {
      if (!acc[item.id]) acc[item.id] = [];
      acc[item.id].push(item);
      return acc;
    }, {});
    const uniqueIds = Object.keys(groupedItems);

    const shuffledIds = uniqueIds.sort(() => 0.5 - Math.random());

    const selectedItems = [];
    for (const id of shuffledIds) {
      if (selectedItems.length >= count) break;
      selectedItems.push(groupedItems[id][0]);
    }

    return selectedItems;
  };

  const findObjectById = (id) => {
    return casesListing.find(item => item.id === id);
  };

  useEffect(() => {
    const result = findObjectById(id);
    setObjectData(result);
  }, [id]);

  return (
    <div className='CaseCompleted'>
      <img className='header-case' src={objectData?.image} alt={objectData?.title} />
      <div className="container-title-case">
        <div className="box-title">
          <h1>{objectData?.title}</h1>
          <p>{objectData?.description}</p>
        </div>
        <div className={`challenge-title ${objectData?.imageChallenge ? '' : 'no-image'}`}>
          <h2>{objectData?.project ? 'O PROGRAMA' : 'O DESAFIO'}</h2>
          <p dangerouslySetInnerHTML={{ __html: objectData?.challenge }}></p>
        </div>
      </div>
      {objectData?.imageChallenge && <div className="container-challenge-image">
        <img className='challenge-image' src={objectData?.imageChallenge} alt="Desafio" />
      </div>}
      <div className="challenge-resolve-container" style={{ backgroundColor: objectData?.background }}>
        <div className="box-challenge-idea">
          <h2>A IDEIA</h2>
          <p dangerouslySetInnerHTML={{ __html: objectData?.idea }}></p>
        </div>
        {!objectData?.project &&
          <div className="box-challenge-result">
            <h2>O RESULTADO</h2>
            <p dangerouslySetInnerHTML={{ __html: objectData?.result }}></p>
          </div>
        }
      </div>

      <div className="cases-recommendation">
        <div className="title-recommendation">
          <h4>VEJA MAIS CASES</h4>
          <button onClick={() => {
            window.scrollTo(0, 0)
            navigate('/')
          }}>Ver todos <div><Arrow /></div></button>
        </div>
        <div className="box-recommendation">
          {getRandomItems(imageWithBadgeData, 2).map((item, index) => (
            <ImageWithBadge
              key={index}
              src={item.src}
              alt={item.alt}
              badgeText={item.badgeText}
              titleText={item.titleText}
              captionText={item.captionText}
              size='100%'
              width='50%'
              height='60vh'
              onClick={() => navigateCase(item.id)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default CaseCompleted
