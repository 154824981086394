import React from 'react';
import { ReactComponent as ArrowBottom } from '../../../assets/images/banner/arrow-bottom.svg';
import './Banner.scss';

const Banner = () => {
  return (
    <div className="banner-container">
      <header className='Banner'>
        <video autoPlay loop disablePictureInPicture muted width='100vw'>
          <source src="/videos/home.webm" type="video/webm" />
        </video>
        <div className='content'>
          <h2>A ESCOLA<br />
            QUE FLUI.</h2>
          <ArrowBottom />
        </div>
      </header>
    </div>
  )
}

export default Banner;
