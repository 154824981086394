import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Arrow } from '../../../assets/images/banner/arrow-right.svg'
import ImageWithBadge from '../ImageWithBadge/ImageWithBadge'
import './FeedCases.scss'

const FeedCases = () => {
  const navigate = useNavigate();

  const navigateCase = (id) => {
    window.scrollTo(0, 0)
    navigate(`/cases/completed/${id}`)
  }

  const openLinkInNewTab = (url) => {
    window.open(url, '_blank').focus();
  };

  return (
    <div className="feedcases-container">
      <div className='FeedCases'>
        <div className="who-we-are">
          <h3>Somos uma escola de tecnologia, <br /> agilidade e inovação para times e empresas.</h3>
          <button>Quem somos <div><Arrow /></div></button>
        </div>
        <div className="container-cases">
          <ImageWithBadge
            src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/case2.png"
            alt="New Image"
            badgeText="CASE"
            titleText="GLOBO | Desafio LED - Me dá uma luz aí."
            captionText="A educação no centro do palco e dos holofotes"
            size='100%'
            width='100%'
            height='70vh'
            onClick={() => navigateCase('led')}
          />
          <ImageWithBadge
            src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/case3.png"
            alt="New Image"
            badgeText="CASE"
            titleText="IBM | Match!"
            captionText="Inteligência artificial e qualificação profissional"
            size='100%'
            width='100%'
            height='70vh'
            onClick={() => navigateCase('match')}
          />
          <div className="box-cases">
            <div className="left-cases">
              <ImageWithBadge
                src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/cases-completo/banque-se2.jpg"
                alt="New Image"
                badgeText="CASE"
                titleText="BANQUE-SE | Grafite, arte e ágil"
                captionText="Quando a arte do grafite encontra os métodos ágeis"
                size='100%'
                width='100%'
                height='60vh'
                onClick={() => navigateCase('banque-se')}
              />
              <ImageWithBadge
                src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/case5.png"
                alt="New Image"
                badgeText="CASE"
                titleText="BAYER |"
                small
                captionText="Inception, uma co-criação inovadora"
                size='100%'
                width='100%'
                height='30vh'
                onClick={() => navigateCase('bayer')}
              />
            </div>

            <div className="right-cases">
              <ImageWithBadge
                src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/cases-completo/alvarez-home.jpg"
                alt="New Image"
                badgeText="CASE"
                titleText="A&M |"
                small
                captionText="Os belts de conhecimento"
                size='100%'
                width='100%'
                height='30vh'
                onClick={() => navigateCase('alvarez')}
              />

              <ImageWithBadge
                src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/case1.png"
                alt="New Image"
                badgeText="CASE"
                titleText="HEINEKEN | Data drive mindset"
                captionText="Transformação digital e data driven mindset"
                size='100%'
                width='100%'
                height='60vh'
                onClick={() => navigateCase('heineken')}
              />
            </div>
          </div>
        </div>
        <div className="container-mdaily">
          <div className="box-mdaily">
            <div className="box">
              <ImageWithBadge
                src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/mdailys/mdaily(1).png"
                alt="New Image"
                mdaily
                badgeText="M/DAILY"
                captionText="Como fechar o buraco do conhecimento em IA?"
                size='100%'
                width='100%'
                height='30vh'
                onClick={() => openLinkInNewTab('https://fabiovagnerribeiro.medium.com/ia-afaste-de-mim-esse-c%C3%A1lice-8480fd4b9b92')}
              />
            </div>
            <div className="box">
              <ImageWithBadge
                src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/mdailys/arte+_+insta+_+m_daily+(2).png"
                alt="New Image"
                mdaily
                badgeText="M/DAILY"
                captionText="Quando tudo é IA, para onde vamos?"
                size='100%'
                width='100%'
                height='30vh'
                onClick={() => openLinkInNewTab('https://fabiovagnerribeiro.medium.com/menos-tecnologia-por-favor-c3c5ae7cc52c')}
              />
            </div>
            <div className="box">
              <ImageWithBadge
                src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/mdailys/arte+_+insta+%2B+lnkd+_+m_daily.png"
                alt="New Image"
                mdaily
                badgeText="M/DAILY"
                captionText="De proibição em proibição, mais perto da extinção"
                size='100%'
                width='100%'
                height='30vh'
                onClick={() => openLinkInNewTab('https://fabiovagnerribeiro.medium.com/proibi%C3%A7%C3%A3o-faz-eco-mas-%C3%A9-oca-13d0a94da875')}
              />
            </div>
            <div className="box">
              <ImageWithBadge
                src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/mdailys/arte+_+insta+_+m_daily+(3)%402x.png"
                alt="New Image"
                mdaily
                badgeText="M/DAILY"
                captionText="Survival thinking"
                size='100%'
                width='100%'
                height='30vh'
                onClick={() => openLinkInNewTab('https://fabiovagnerribeiro.medium.com/pensamento-de-sobreviv%C3%AAncia-708432c44f9f')}
              />
            </div>
          </div>

          <div className="box-mdaily">
            <div className="box">
              <ImageWithBadge
                src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/mdailys/two-m-daily.png"
                alt="New Image"
                mdaily
                badgeText="M/DAILY"
                captionText="Uma I.A. sem cores"
                size='100%'
                width='100%'
                height='30vh'
                onClick={() => openLinkInNewTab('https://fabiovagnerribeiro.medium.com/uma-vida-mastigando-apenas-br%C3%B3colis-c9b2a70b9291')}
              />
            </div>
            <div className="box">
              <ImageWithBadge
                src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/mdailys/arte+_+insta+_+m_daily+(4).png"
                alt="New Image"
                mdaily
                badgeText="M/DAILY"
                captionText="O clique mental e o custo por clique"
                size='100%'
                width='100%'
                height='30vh'
                onClick={() => openLinkInNewTab('https://fabiovagnerribeiro.medium.com/n%C3%A3o-s%C3%A3o-apenas-as-%C3%A1guas-de-mar%C3%A7o-05f0060ccf3f')}
              />
            </div>
            <div className="box">
              <ImageWithBadge
                src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/mdailys/arte+_+lnkd+_+m_daily.png"
                alt="New Image"
                mdaily
                badgeText="M/DAILY"
                captionText="Abafadas pela FIFA"
                size='100%'
                width='100%'
                height='30vh'
                onClick={() => openLinkInNewTab('https://fabiovagnerribeiro.medium.com/pelo-fim-da-prorroga%C3%A7%C3%A3o-e-das-penalidades-65f1048fa220')}
              />
            </div>
            <div className="box">
              <ImageWithBadge
                src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/mdailys/arte+_+lnkd+_+m_daily+(1).png"
                alt="New Image"
                mdaily
                badgeText="M/DAILY"
                captionText="Quem vive pelo canto não emudece jamais"
                size='100%'
                width='100%'
                height='30vh'
                onClick={() => openLinkInNewTab('https://fabiovagnerribeiro.medium.com/cont%C3%A9m-celine-13cda9a23c86')}
              />
            </div>
          </div>
        </div>
        <div className="assinature-mdaily">
          <button onClick={() => openLinkInNewTab('https://materiais.mastertech.com.br/mdaily-20202')}>Assine o M/Daily de graça <div><Arrow /></div></button>
          <h3>Aprendemos para ensinar, ensinamos para aprender <br /> e questionamos para avançar em todo e qualquer projeto,<br /> inclusive (e principalmente) nos autorais,<br /> que criamos aqui dentro e compartilhamos com o mundo.</h3>
        </div>
        <div className="container-projects">
          <div className="left-cases">
            <ImageWithBadge
              src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/projetos/2702_Abertura+Impulso+(2)+(1).png"
              alt="New Image"
              badgeText="PROJETO"
              titleText="SOMAS | Programa Impulso"
              captionText="Nosso programa de empreendedorismo social"
              size='100%'
              width='100%'
              height='60vh'
              onClick={() => navigateCase('impulso')}
            />
            <ImageWithBadge
              src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/projetos/Imagem+24.png"
              alt="New Image"
              badgeText="PROJETO"
              titleText="SOMAS | Computador circular"
              captionText="Educação acessível e circular"
              size='100%'
              width='100%'
              height='30vh'
              onClick={() => navigateCase('pc-circular')}
            />
          </div>

          <div className="right-cases">
            <ImageWithBadge
              src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/projetos/projeto.png"
              alt="New Image"
              badgeText="PROJETO"
              titleText="SOMAS | Computador circular"
              captionText="Educação acessível e circular"
              size='100%'
              width='100%'
              height='30vh'
              onClick={() => navigateCase('pc-circular')}
            />

            <ImageWithBadge
              src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/projetos/Imagem+11.png"
              alt="New Image"
              badgeText="PROJETO"
              titleText="HEY IA | Nossas reflexões sobre IA"
              captionText="Compre e apoie os projetos sociais da Mastertech"
              size='100%'
              width='100%'
              height='60vh'
              onClick={() => navigateCase('hey-ia')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeedCases
