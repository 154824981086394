import React from 'react';
import { Link } from 'react-router-dom';
import ContactForm from '../ContactForm/ContactForm';
import './Footer.scss';

const Footer = () => {
  return (
    <div className="container-footer">
      <footer className="Footer">
        <div className="footer-contact">
          <h2>Vamos nos conectar?</h2>
          <p>Deixe o seu contato aqui.</p>
          <ContactForm />
        </div>
        <div className="footer-info">
          <h3>MASTERTECH</h3>
          <div className="footer-info-content">
            <div>
              <span>São Paulo</span>
              <p>Av. Brigadeiro Luis Antônio, 2696</p>
              <p>Bela Vista</p>
              <p>CEP 01402-000</p>
              <p>TEL 55 11 91952-2455</p>
            </div>
            <div>
              <a href='mailto:oi@mastertech.com.br' target="_blank" rel="noreferrer">
                <span>E-mail</span>
              </a>
              <a href='https://www.instagram.com/mastertech.tech/' target="_blank" rel="noreferrer">
                Instagram
              </a>
              <a href='https://www.youtube.com/channel/UCVAwnfMBdeA7Zz2fQf8fo_w' target="_blank" rel="noreferrer">
                Youtube
              </a>
              <a href='https://www.facebook.com/mastertech.tech/?locale=pt_BR' target="_blank" rel="noreferrer">
                Facebook
              </a>
              <a href='https://br.linkedin.com/school/mastertech.tech/' target="_blank" rel="noreferrer">
                Linkedin
              </a>
            </div>
            <div>
              <span>2024 Mastertech</span>
              <Link to='/politica-de-privacidade'>
                Políticas de privacidade
              </Link>
              <Link to='/portaldotitular'>
                Portal do Titular
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
