export const imageWithBadgeData = [
  {
    src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/case2.png",
    alt: "New Image",
    badgeText: "CASE",
    titleText: "GLOBO | Desafio LED - Me dá uma luz aí.",
    captionText: "A educação no centro do palco e dos holofotes",
    id: "led"
  },
  {
    src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/case3.png",
    alt: "New Image",
    badgeText: "CASE",
    titleText: "IBM | Match!",
    captionText: "Inteligência artificial e qualificação profissional",
    id: "match"
  },
  {
    src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/cases-completo/banque-se2.jpg",
    alt: "New Image",
    badgeText: "CASE",
    titleText: "BANQUE-SE | Grafite, arte e ágil",
    captionText: "Quando a arte do grafite encontra os métodos ágeis",
    id: "banque-se"
  },
  {
    src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/case5.png",
    alt: "New Image",
    badgeText: "CASE",
    titleText: "BAYER |",
    small: true,
    captionText: "Inception, uma co-criação inovadora",
    id: "bayer"
  },
  {
    src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/cases-completo/alvarez-home.jpg",
    alt: "New Image",
    badgeText: "CASE",
    titleText: "A&M |",
    small: true,
    captionText: "Os belts de conhecimento",
    id: "alvarez"
  },
  {
    src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/case1.png",
    alt: "New Image",
    badgeText: "CASE",
    titleText: "HEINEKEN | Data drive mindset",
    captionText: "Transformação digital e data driven mindset",
    id: "heineken"
  },
  {
    src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/projetos/Imagem+24.png",
    alt: "New Image",
    badgeText: "CASE",
    titleText: "SOMAS | Computador circular",
    captionText: "Educação acessível e circular",
    id: 'pc-circular'
  },
  {
    src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/projetos/projeto.png",
    alt: "New Image",
    badgeText: "CASE",
    titleText: "SOMAS | Computador circular",
    captionText: "Educação acessível e circular",
    id: 'pc-circular'
  },
  {
    src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/projetos/Imagem+11.png",
    alt: "New Image",
    badgeText: "CASE",
    titleText: "HEY IA | Nossas reflexões sobre IA",
    captionText: "Compre e apoie os projetos sociais da Mastertech",
    id: 'hey-ia'
  }
];
