import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.scss';
import Cookies from "./components/common/Cookies/Cookies";
import WhatsAppButton from "./components/common/WhatsAppButton/WhatsAppButton";
import Cart from "./components/ui/Cart/Cart";
import Footer from "./components/ui/Footer/Footer";
import Navbar from "./components/ui/Navbar/Navbar";
import { CartProvider } from "./hooks/useCart";
import B2BCourse from "./pages/B2BCourse/B2BCourse";
import B2CCourse from "./pages/B2CCourse/B2CCourse";
import CaseCompleted from "./pages/CaseCompleted/CaseCompleted";
import Contact from "./pages/Contact/Contact";
import ForCompanies from "./pages/ForCompanies/ForCompanies";
import ForPeople from "./pages/ForPeople/ForPeople";
import HolderPortal from "./pages/HolderPortal/HolderPortal";
import Home from "./pages/Home/Home";
import MultiEcommerce from "./pages/MultiEcommerce/MultiEcommerce";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import PasswordRecovery from "./pages/PasswordRecovery/PasswordRecovery";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ProtectionPolicy from "./pages/ProtectionPolicy/ProtectionPolicy";
import SetNewPassword from "./pages/SetNewPassword/SetNewPassword";
import Solutions from "./pages/Solutions/Solutions";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import { getItemFromLocalStorage } from "./utils/localStorage";

const App = () => {
  const [invertedNavbar, setInvertedNavbar] = useState();
  const [sendToBack, setSendToBack] = useState();
  const [openCart, setOpenCart] = useState(false);

  return (
    <div className="App">
      <BrowserRouter>
        <CartProvider>
          <WhatsAppButton />
          <Navbar openCart={openCart} setOpenCart={setOpenCart} inverted={invertedNavbar} sendToBack={sendToBack} />
          {getItemFromLocalStorage("cookie") ? "" : <Cookies />}
          <Routes>
            <Route path="/" element={<Home setInvertedNavbar={setInvertedNavbar} setSendToBack={setSendToBack} />} />
            <Route path="/para-empresas" element={<ForCompanies setSendToBack={setSendToBack} />} />
            <Route path="/para-empresas/cursos/:id" element={<B2BCourse />} />
            <Route path="/para-pessoas" element={<ForPeople />} />
            <Route path="/para-pessoas/cursos/:id" element={<B2CCourse />} />
            <Route path="/cases/completed/:id" element={<CaseCompleted />} />
            <Route path="/solucoes" element={<Solutions />} />
            <Route path="/compra/:id" element={<MultiEcommerce />} />
            <Route path="/contato" element={<Contact />} />
            <Route path="/portaldotitular" element={<HolderPortal />} />
            <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
            <Route path="/politica-de-protecao-de-dados" element={<ProtectionPolicy />} />
            <Route path="/termos-condicoes" element={<TermsConditions />} />
            <Route path="/resetar-senha" element={<PasswordRecovery />} />
            <Route path="/resetar-senha/fim/:token" element={<SetNewPassword />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Footer hideOnHome />
          {openCart && <Cart setOpenCart={setOpenCart} />}
        </CartProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
