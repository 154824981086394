import React from 'react'
import bayer from '../../../assets/images/partners/bayer.svg'
import globo from '../../../assets/images/partners/globo.svg'
import heineken from '../../../assets/images/partners/heineken.png'
import ibm from '../../../assets/images/partners/ibm.svg'
import itau from '../../../assets/images/partners/itau.svg'
import nestle from '../../../assets/images/partners/nestle.svg'
import santander from '../../../assets/images/partners/santander.svg'
import vivo from '../../../assets/images/partners/vivo.svg'
import './Partners.scss'

const Partners = () => {
  const partners = [
    {
      name: 'Heineken',
      image: heineken,
    },
    {
      name: 'Globo',
      image: globo,
    },
    {
      name: 'Bayer',
      image: bayer,
    },
    {
      name: 'IBM',
      image: ibm,
    },
    {
      name: 'Santander',
      image: santander,
    },
    {
      name: 'Vivo',
      image: vivo,
    },
    {
      name: 'Nestle',
      image: nestle,
    },
    {
      name: 'Itaú',
      image: itau,
    }
  ]
  return (
    <div className="container-partners">
      <div className='Partners'>
        <h4>Há mais de 10 anos levando transformação <br /> digital para empresas que pensam em pessoas.</h4>
        <div className="container-partners">
          {partners.map((partner, index) => (
            <img
              key={index}
              src={partner.image}
              alt={partner.alt}
              className="partner-image"
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Partners
